import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import LazyLoadDirective from '@/directives/LazyLoadDirective'

import Unicon from 'vue-unicons'

import {
  uniAngleDown,
  uniAngleUp,
  uniBars,
  uniTimes,
  uniTwitter,
  uniFacebookF,
  uniCopyright
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniAngleDown,
  uniAngleUp,
  uniBars,
  uniTimes,
  uniTwitter,
  uniFacebookF,
  uniCopyright
])

createApp(App)
  .use(Unicon)
  .directive("lazyLoad", LazyLoadDirective)
  .use(router)
  .mount('#app')
