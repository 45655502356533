<template>
  <main>
    <h1>Graduate Student Consortium</h1>

    <h2>Important Dates</h2>

    <table>
      <thead>
      <tr>
        <th>
          Event
        </th>
        <th>
          Deadline
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          Submission deadline
        </td>
        <td>
          October 24, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of acceptance
        </td>
        <td>
          November 4, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          November 28, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 conference
        </td>
        <td>
          March 4-7, 2025
        </td>
      </tr>
    </table>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>

    <p>TEI2025 will host a Graduate Student Consortium (GSC) aimed at junior researchers currently in post-baccalaureate
      degree programmes (i.e., doctoral programmes in research fields, and master’s programmes in the arts) in an area
      relevant to the Tangible, Embedded and Embodied Interaction community. Traditionally the TEI GSC has focused on
      meeting other TEI graduate students as well as more experienced TEI researchers to share experiences and sharpen
      the research.</p>

    <h2>General information</h2>

    <p>The main event is a one-day session on Tuesday, March 4 2025, during which TEI graduate students will present
      their work to, and discuss their work with each other and with a panel of experienced TEI researchers. During this
      Tuesday program, the key purpose is to have an informal and interactive discussion about each candidate’s ongoing
      research, with the intention to sharpening it. Applicants to the GSC may be at various stages of their PhD
      research or graduate thesis, but should have a clear understanding of their driving research question, their
      research approach, including preliminary research design and methodology, and a goal for what they hope to gain
      from attending the GSC session.</p>

    <h2>GSC activities</h2>

    <p>The main GSC panel will be held on Tuesday, March 4 2025. The day will start with a welcome and introduction by
      the GSC chairs. Then, each student will have to present and participate in a group discussion about their work.
      The day will end with a general discussion and wrap-up.</p>

    <p>We are hoping to offer financial
      support to a limited number of Graduate Student Symposium participants based at US institutions. At this moment
      there is no information yet about further potential funding for GSC-attendees, but it will be posted on this
      website as soon as we know more.</p>

    <h2>Submission guidelines</h2>
    <h3>GENERAL</h3>
    <p>Each applicant should provide a short written paper describing their ongoing research. The deadline for the paper
      is Thursday, October 24, 2024 AoE.</p>

    <p>The paper should clearly describe the <strong> research topic</strong>, covering the <strong> context and
      motivation, objectives,</strong> and
      <strong> central research questions</strong>. Also we require a brief but concise <strong>background </strong>description
      and <strong>literature review</strong>
      framing the research, a description and rationale of the approach to <strong>research design</strong> and
      methodology, and an
      overview of <strong>data collection and analysis</strong> methods. If possible and suitable, please also include a
      description of
      pilot, preliminary or selected <strong>results</strong>, framed in the context of your research questions.
      Finally, the paper
      should provide a brief <strong>timeline</strong> that gives insight into the activities done so far and those
      planned for the
      remainder of the doctoral research. Please also state your institutional affiliation (including the department)
      including your (main) supervisor and the amount of time left in your dissertation work.</p>

    <p>The selection of GSC participants will be made by the GSC chairs. Accepted GSC papers will be published in the
      TEI Proceedings and in the ACM Digital Library.</p>

    <h3>FORMAT REQUIREMENTS & SUBMISSION PROCESS </h3>
    <p>The submission should be based on the new ACM submission templates also used for the <a
        href="https://tei.acm.org/2025/participate/papers" target="_blank">TEI paper track</a> (more information on the
      ACM templates can be found <a href="https://www.acm.org/publications/authors/submissions" target="_blank">here</a>
      and uploaded to the Precision Conference (PCS) system, which will be open in September. (Indication regarding
      length: If processed into the two-column output, please try not to exceed four pages excluding references). Please
      note that submissions to the TEI 2025 Graduate Student Consortium should NOT be anonymized.
    </p>

    <p> All papers must be submitted electronically via the <a href="https://new.precisionconference.com/"
                                                               target="_blank">Precision Conference (PCS) website.</a>
    </p>

    <h3>CONFIDENTIALIY</h3>
    <p>Confidentiality of submissions will be maintained during the review process. All rejected submissions will be kept confidential in perpetuity. All submitted materials for accepted submissions will be kept confidential until the start of the conference.</p>

    <h2>GSC CHAIRS</h2>
    Wendy Mackay, <i>Inria, Université Paris-Saclay</i>, France<br>
    Jörn Hurtienne, <i>Julius-Maximilians-Universität Würzburg</i>, Germany<br>
    Ellen Yi-Luen Do, <i>University of Colorado Boulder</i>, USA<br>
    <br>
    For further questions please contact the GSC chairs at <a href="mailto:gsc_chairs@tei.acm.org">gsc_chairs@tei.acm.org</a>
  </main>
</template>

<script setup>
</script>

<style scoped>
h1 {
  width: 737px;
}

table {
  max-width: 500px;
}

</style>
