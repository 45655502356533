<template>
  <main>
    <h1>Student Design Competition</h1>
    <h2>Important Dates</h2>
    <table>
      <thead>
      <tr>
        <th>
          Event
        </th>
        <th>
          Deadline
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          Submission deadline
        </td>
        <td>
          <s>October 23, 2024, AoE</s> <br>
          November 4, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of acceptance
        </td>
        <td>
          November 18, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          December 2, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 conference
        </td>
        <td>
          March 4-7, 2025
        </td>
      </tr>
    </table>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>

    <h2>Doing sustainable research: <i>The Design Challenge</i></h2>

    <p>
      We are increasingly striving for more sustainable ways of living. These goals are yet to filter down to our
      research practices, though. We continue to pursue relentless technical and interaction innovations, chasing novelty, with little
      thought for the underlying implications for sustainability.
    </p>

    <p>
      <i>Sustainability is not a widely recognised quality criteria for research or innovation. Could it be?</i>
    </p>
    <p>
      Your challenge is to think about sustainability practices and criteria for research. What could be appropriate
      practices? How could sustainability outcomes be compared, what could be the benchmarks? Should we simply be using recycled materials? Should we be reporting how new
      artifacts are made from tearing down old artifacts? Should we prioritize highly reusable technologies over custom-engineered artifacts? Is a research artifact that leads to a
      commercial product somehow the ultimate sustainability outcome? Through this exercise, you should consider: What do you consider to be criteria for sustainable research and
      innovation?
    </p>
    <p>
      Wobbrock (2012) outlines artifact contributions as inventions (systems, architectures, tools, techniques, designs,
      etc.) that "reveal new insights or explorations, or impel us to consider new futures." In light of your position on sustainable research, we’d like you to explore
      such new futures by taking a recent artifact contribution in HCI and redesigning it to achieve or improve on your sustainability criteria or benchmarks. That is, take a piece
      of research and make it more sustainable. This could mean a myriad of different things; for example, you could choose to turn an existing piece of research to enhancing
      sustainability (i.e., using a tangible artifact to prompt users of their water consumption), simply lessen the impact of a piece of research on our planet by using recycled materials, or demonstrate how a
      novel idea could be achieved by tearing down some existing artifacts.
    </p>

    <p>
      Finally, it's important to consider whether a move to sustainability needs to inherently reduce or harm our
      ability to be forward-thinking and innovative. Does sustainability stand in the way of true, blue-sky research? What has been lost or gained through your design
      exploration, as a result of the need to consider sustainability
    </p>

    <h2>How to participate</h2>
    <p>
      For the Student Design Challenge (SDC), we invite students to contribute by submitting a 4-page document
      (pictorial or paper), vision video and, if selected, a physical prototype which will be exhibited at the TEI
      conference.
    </p>

    <p>
      We do not expect to receive fully functional prototypes, but rather probes, mock-ups, and videos that explore
      and demonstrate how sustainability might become a criteria for research practices. We expect to see (a) discussion
      of high-level sustainability considerations, (b) a proposed artifact or system that both reflects an existing or
      new contribution to TEI, but achieves it through greater sustainability, and (c) reflection on how the pursuit of
      sustainability impacted that research vision. The high-level goal is to provoke discussion and speculation as to
      how we can strive for more sustainable research, especially at artifact-focussed venues.
    </p>

    <p>
      Accepted TEI 2025 Student Design Challenge submissions will become a physical part of an exhibition we will run
      at the in-person part of the TEI conference. Accepted papers will be published in the TEI2025 proceedings, part of
      the ACM Digital Library. Three prizes will be awarded:
    </p>

    <ul>
      <li> Most Innovative Vision on Sustainability (Juried)</li>
      <li> Best Physical Prototype (Juried)</li>
      <li> People’s Choice Award (Attendee voted)</li>
    </ul>

    <p> The jury will further prepare a Jury Statement for all accepted submissions. </p>

    <b>USEFUL RESEARCH EXAMPLES AND PROMPTS</b>

    <p> Do the additional engineering work to make your device ultra-low power:
      e.g., <i> UbiquiTouch: Self-Sustaining Ubiquitous Touch Interfaces, Waghmare et al.</i>
    </p>

    <p>Consider bio-materials that can be made and biodegrade:
      e.g., <i> Making Biomaterials for Sustainable Tangible Interfaces, Bell et al. </i> and <i> Chitosan Biofilm
        Actuators: Humidity Responsive Materials for Sustainable Interaction Design, Teuling et al.</i>
    </p>

    <p>Use TEI ideas to increase the conversation around sustainability:
      e.g., <i> Are Conferences Sus?: Fostering Conversations on the Sustainability of HCI Conferences Through Data
        Physicalization, Hayes et al.</i>
    </p>

    <p>Designing for recycling and alteration:
      e.g., <i> Punch-Sketching E-textiles: Exploring Punch Needle as a Technique for Sustainable, Accessible, and
        Iterative Physical Prototyping with E-textiles, Jones et al.</i>
    </p>

    <p>Thinking about sustainability in ‘quick-fix’ design:
      e.g., <i> Ephemeral Fabrication: Exploring a Ubiquitous Fabrication Scenario of Low-Effort, In-Situ Creation of
        Short-Lived Physical Artifacts, Stemasov et al.</i></p>

    <p>Thinking about Longevity:
      e.g., <i>Long-lasting smart products: Overview of longevity concepts in sustainable ICT and design for
        sustainability, Özcelik et al. </i></p>

    <h2>Submission guidelines</h2>
    <p>This year’s TEI Student Design Challenge will take the form of designs of more sustainable research artifacts.
      Students should submit a demonstration video
      and a document with rationale/motivation for their particular vision.
      Submissions will be selected by the SDC chairs and should include all author names, affiliations and contact
      information, and are thus not anonymous.
    </p>
    <p> Upon acceptance, we invite students to bring a physical demonstration model which will be exhibited at the
      in-person part of the conference.
    </p>

    <h2>Format requirements</h2>
    <p>
      TEI2025 uses the new ACM workflow for submission templates and published papers. Specific to the Student Design
      Challenge, submissions should be made in the two-column template. You will have to submit an up to 4-page
      (including
      references) paper, or pictorial (for guidance and templates, see the pictorials section of the TEI 2025 website),
      a high-resolution image, and a video. You will also have to commit to producing an exhibition piece for the
      in-person conference, which you can prepare on-site and attend the panel discussion.
    </p>
    <p>
      The paper/pictorial should clearly describe the submitted vision and give a rationale for the decisions that led
      to
      this vision. You should also include a high-resolution photo of the exhibition piece as a separate file. The
      acknowledgements section should indicate whether any assistance was drawn from outside the student team (e.g.,
      from
      advisors, faculty, domain experts, existing solutions, users, etc.).
    </p>
    <p>
      We will showcase the visions at an exhibition in the live-conference. This exhibition will serve as a platform for
      conversation during the conference and beyond.
    </p>
    <p>
      The video length should be a maximum of 5 minutes. The video should demonstrate your vision and ideally use the
      exhibition piece to illustrate this: what is your perspective on sustainability? How does your artifact represent
      a
      more sustainable research practice? How is it made? You are free to upload the video on other channels for
      publicity.
    </p>
    <p>
      Submissions should not contain sensitive, private, or proprietary information that cannot be disclosed at
      publication time. You might perhaps also be submitting your project to other TEI formats such as the WIP or Demos.
      This is allowed, but you will still need to produce and submit a tailored submission and video for the SDC and
      attend the design exhibition. The different kinds of conference submission will be reviewed and handled
      independently.
    </p>
    <h2>Selection process</h2>
    <p>
      Submissions will be reviewed and selected by the SDC Chairs. The submissions should include all author names,
      affiliations and contact information, and are thus not anonymous. Confidentiality of submissions will be
      maintained during the review process. All rejected submissions will be kept confidential in perpetuity.
    </p>
    <h2>Material to submit upan acceptance</h2>
    <p>
      Authors of accepted submissions will be notified on November 18, 2024. Accepted submissions should be finalized by
      preparing the camera-ready revisions of the paper and submitted by December 2, 2024.
    </p>

    <h2>At the conference</h2>
    <p>
      During the TEI conference, authors will present their designs during an in-person exhibition. Presenters can use a
      demo or poster format, or a combination thereof, to showcase their artifact, strategies for sustainability, and
      other aspects considered in the design. Conference attendees will be invited to this exhibition and will be given
      prompt card(s) with questions to generate conversation and questions, opening up new dialogues about
      sustainability
      and challenging its consideration as a criteria for research. Attendees will vote for their favorite
      sustainability
      approaches as they tour the exhibit.
    </p>

    <h2>Attendance</h2>
    <p>
      At least one author of each accepted submission must register for the conference before the early registration
      deadline in order for the final paper to be published in the conference proceedings and the exhibit to be shown at
      the conference.
    </p>
    <b>VISA INFORMATION</b>
    <p>
      ACM is able to provide visa support letters to attendees as well as authors with accepted papers, posters, or
      members of the conference committee.
    </p>
    <p>
      If you are an accepted author (i.e., your submission was accepted) who needs a visa support letter in advance of
      registration you should fill out the form at https://supportletters.acm.org. Please allow up to 10 business days
      to
      receive a letter. All requests are handled in the order they are received. Please notice that this is only
      possible
      for accepted authors.
    </p>
    <p>
      All other visa support letters can be requested through registration.
    </p>


    <h2>Submission process</h2>
    <p>
      All work except the exhibition piece itself must be submitted through the Precision Conference submission system
      (PCS). If any of the above information is unclear or you have any questions, please don’t hesitate to contact us,
      the design challenge chairs:
    </p>

    <h2>Student design competition chairs</h2>
    Courtney Reed, <i> Loughborough University London, UK </i><br/>
    Jarrod Knibbe, <i> University of Queensland, Australia </i><br/>
    <br/>
    For further questions please contact the Student Design Competition chairs at <a
      href="mailto:sdc_chairs@tei.acm.org">sdc_chairs@tei.acm.org</a>

  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}
</style>
