<template>
<main>
  <h1>Studio Program</h1>
</main>
</template>

<script setup>
</script>

<style scoped>

</style>
