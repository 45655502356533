<template>
  <main>
    <h1>Papers</h1>
    <h2>Important Dates</h2>

    <table>
      <thead>
      <tr>
        <th>
          Event
        </th>
        <th>
          Deadline
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          Abstract deadline
        </td>
        <td>
          July 25, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Full submission deadline
        </td>
        <td>
          August 1, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of conditional acceptance
        </td>
        <td>
          October 9, 2024
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          November 22, 2024
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 conference
        </td>
        <td>
          March 4-7, 2025
        </td>
      </tr>
    </table>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>


    <h2>General Information</h2>
    <p>
      TEI 2025 is the 19th annual conference dedicated to tangible, embedded, and embodied interaction. The ACM TEI conference brings together researchers, practitioners, artists, designers and students from a variety of disciplines including interaction design, computer science, engineering, product design, media studies and the arts. The intimate size of this single-track conference provides a unique forum for exchanging ideas and presenting innovative work through talks, demonstrations, posters, art installations, performances, hands-on studios, and scholarly workshops.
    </p>
    <p>
    We invite paper submissions from a wide range of perspectives: technical, applied, empirical, theoretical, philosophical, conceptual, and artistic. We welcome contributions from fields including but not limited to: tangible interaction design, embodied interaction design, embedded computing, digital fabrication, hybrid craft, e-textiles, wearables, haptics, robotics, smart cities, smart materials, and sustainability.
    </p>
    <p>
      The 2025 conference theme is <b>Sustainable TEI</b> and we are particularly interested in contributions that relate to this theme. Topics of interest include: conservation, sustainable materials, sustainable design and engineering processes, e-waste, and climate change. We also welcome submissions that relate to the cultural and social sustainability of TEI. Areas of interest include: community development and support, mentorship, lifelong learning, diversity, and inclusion.
    </p>

    <h2>Contribution types</h2>

    <p>
    Authors are invited to submit high-quality papers that contribute to advancing this rapidly developing field. We highlight the following non-exclusive set of contribution types:
    </p>
      <ul>
      <li>
        <b>Artifact:</b> Research artifacts that advance the state of the art in Tangible, Embedded and Embodied Interaction. Artifacts can demonstrate new technologies (e.g., new sensing techniques or algorithms), new forms of input (e.g., novel interaction techniques) or new designs (e.g., provocative or evocative objects, systems or services).
      </li>
      <li>
        <b>Method:</b> Tools, approaches and techniques that enable researchers, technologists, designers and practitioners to study, research and work in Tangible, Embedded and Embodied Interaction. Methods can include new forms of study design or data analysis as well as new engineering processes or frameworks.
      </li>
      <li>
        <b>Theory:</b> Explorations, extensions, refutions, instantiations and other developments of and to the theories pertaining to Tangible, Embedded and Embodied Interaction, such as theories of cognition or the mind as well as design theories and conceptual frameworks.
      </li>
      <li>
        <b>Empirical:</b> Studies and data that add to our understanding of Tangible, Embedded and Embodied Interaction by, for example, providing quantitative accounts of salient aspects of human performance or qualitative characterizations of experiences with tangible artifacts and systems. Empirical submissions can detail outcomes from a very wide range of lab, field and online studies.
      </li>
      </ul>
    <p>
    Respecting the diversity of approaches and methods that together make up TEI, each contribution type will be peer-reviewed on its own merits. We seek high-quality work regardless of the specific subdomain or topic and we expect the work to be positioned firmly in, and building on, prior research in our field.
    </p>
    <p>
    Authors are encouraged to submit a paper with a length proportional to its contribution. There is no maximum or minimum length for papers. Reviewers will be instructed to weigh the contribution of a submission relative to its method and length. Papers should be succinct, but thorough in presenting the work. Shorter, more focused papers are encouraged and will be reviewed like any other paper. Papers whose lengths are incommensurate with their contributions will be rejected.
    </p>
    <p>
    Accepted submissions of all contribution types will be included as papers in the conference proceedings, which will be available in the ACM Digital Library.
    </p>

    <h2>Submission guidelines</h2>
    <b>FORMAT REQUIREMENTS</b>
    <p>
      TEI 2025 uses the new ACM workflow TAPS for submission templates and published papers. TAPS requires the use of a simplified one-column template for submission, while the final two-column paper will be rendered for publication after acceptance. We strongly recommend using the LaTeX templates rather than Word. Papers do not have a page limit. All relevant information, including submission templates in Word and LaTeX, can be found here: <a href="https://www.acm.org/publications/authors/submissions">https://www.acm.org/publications/authors/submissions</a>
    </p>

    <b>AUTHORSHIP POLICY</b>
    <p>Your submission must be original. TEI only accepts original submissions. This means that your submission cannot have been published in another peer-reviewed venue or be under concurrent review at another peer-reviewed venue. Similarly, if you make multiple submissions to TEI 2025 Papers, their contributions must be distinct & self-contained from each other. Please refer to the <a href="https://www.acm.org/publications/policies/new-acm-policy-on-authorship">ACM policy on authorship</a> for details. Authors should avoid using text generated by AI-tools (e.g., LLMs such as ChatGPT or similar). The use of AI-tools must be disclosed in the acknowledgements section of your submission.
    </p>

    <b>ANONYMIZATION POLICY</b>
    <p>
    All papers must be anonymized for review. Author and affiliation sections and credits must be left blank. Authors of accepted submissions will add this information in preparation of the “camera-ready” version. We are using <a href="https://dl.acm.org/journal/imwut/author-policies#:~:text=with%20the%20Editors.-,Anonymization,in%20the%20PDF%20meta%2Ddata">the ACM IMWUT Anonymization Policy</a>. We use a relaxed model that does not attempt to conceal all traces of identity from the body of the paper.
    </p>
    <p>
    Authors are expected to remove author and institutional identities from the title and header areas of the paper. Make sure that no description that can easily reveal authors’ names and/or affiliations is included in the submission (e.g., too detailed descriptions of where user studies were conducted). Also, please make sure that identifying information does not appear in the document’s meta-data (e.g., the ‘Authors’ field in your word processor’s ‘Save As’ dialog box). In addition, we require that the acknowledgments section be left blank as it could also easily identify the authors and/or their institution.
    </p>
    <p>
    Further suppression of identity in the body of the paper is left to the authors’ discretion. We do expect that authors leave citations to their previous work unanonymized so that reviewers can ensure that all previous research has been taken into account by the authors. However, authors are encouraged, but not required, to cite their own work in the third person, e.g., avoid “<i>As described in our previous work [10], … </i>” and use instead “<i>As described by Jones et al. [10], …</i>”
    </p>

    <b>SUBMISSION</b>
    <p>
    All abstracts and, subsequently, full papers, must be submitted electronically via the <a href="https://new.precisionconference.com/">Precision Conference (PCS) website</a> before their respective deadlines (see above for all relevant dates). At time of submission, authors must select their preferred presentation format. If authors chose to present their work as a demonstration, authors will be asked to provide basic requirements of their demo.
    </p>
    <p>
    Regardless of length and contribution type, a paper may be scheduled as a talk, demo and/or poster. Please reflect on what you find the most appropriate presentation format for your work is. All submissions accepted in the paper track are published as archival full papers, irrespective of the chosen presentation format.
    </p>

    <b>REVIEW PROCESS</b>
    <ul>

    <li>
    After a paper-bidding process by the ACS, the program chairs will assign each paper to a primary AC (1AC) and a secondary AC (2AC). The program chairs will identify ACs with expertease in the topic area of each submission
    </li>
    <li>
    The 1AC will find two external reviewers. Each external reviewer and the 2AC will write a detailed review of their assigned submissions and assess the contribution of the research to the field. Thus, each paper will receive 3 detailed reviews.
    </li>
    <li>
    Once the reviews are written, the 1AC is responsible for the quality of the reviews and will write a meta review of the paper, summarizing the reviews from the two external reviewers and the 2AC.  If 1ACs disagree with the other reviews, they are encouraged to write a separate review in addition to the meta-review.
    </li>
    <li>
    The 1AC will present a recommendation for the paper’s acceptance or rejection to the Program Chairs. Program Chairs will decide the final acceptance of all papers for inclusion in the program in a discussion phase, based on the 1AC recommendation, the reviews and fit to the conference.
    </li>
    </ul>

    <b>AFTER ACCEPTANCE</b>
    <p>
    After conditional acceptance, authors will have the opportunity to implement minor modifications at the discretion of the Program Chairs. Authors will receive confirmation of final acceptance, after the camera ready version is submitted. One author of each accepted paper is required to register for TEI 2025. Accepted papers will be included in the Proceedings of TEI 2025, and will become available in the ACM Digital Library and be distributed digitally to conference delegates as part of the conference proceedings.
    </p>
    <p>
      At the conference, authors of accepted submissions must present their work and be available to answer questions from other conference participants. Presenters of papers will have a presentation slot at the conference of approximately 20 minutes, though this may be altered prior to the conference based on scheduling needs. Information on presentations will be sent by email to the corresponding author. Papers whose authors do not present in any form (talk, demo and/or poster) may be removed from the ACM Digital Library and the conference proceedings.
    </p>
    <p>
    Accepted authors should ensure they have obtained permissions to use licensed content and images that depict identifiable people in their conference contributions (paper/pictorial, videos, and presentations). Authors will also be required to give permissions to include their contributions in the ACM Digital Library. Authors can either assign copyright or a license to the ACM or they can pay a fee to ACM for open access. More information on rights management can be found here: <a href="https://authors.acm.org/">https://authors.acm.org/</a>. Finally, and as part of this rights management process, presenting authors will be asked to opt- in to and grant permission to record and/or stream their presentations at the conference.
    </p>


    <b>CONFIDENTIALITY</b>
    <p>
    Confidentiality of submissions will be maintained during the review process. All rejected submissions will be kept confidential in perpetuity. All submitted materials for accepted submissions will be kept confidential until publication.
    </p>

    <h2>TAPS procedure</h2>
    <p>
    Further details on the TAPS publication procedure, what happens after acceptance of your paper? Read the following page carefully if you have not yet done so:
    <a href="https://www.acm.org/publications/authors/submissions">https://www.acm.org/publications/authors/submissions</a>
    For papers to be published via TAPS, the following steps will take place:
    </p>
    <ul>
      <li>
      Some time after you submitted the copyright form that you receive from ACM, you will receive an email from TAPS asking you to upload your source files and supplementary files to TAPS.
      </li>
      <li>
    We strongly suggest using the LaTeX template instead of the Word template for preparing the camera-ready (TAPS) version. Finalizing the submission using the Word template/workflow has caused authors some frustration. Please use the ACM Master Article Class and the included template. It does not matter which <span class="code">\documentclass</span> you choose because TAPS will automatically apply <span class="code">\documentclass[sigconf]{acmart}</span> to your source.
      </li>
      <li>
    If you are using the Word template, please prepare and submit your camera-ready version as a single-column manuscript by following these ACM provided guidelines: <a href="https://www.acm.org/publications/authors/submissions">https://www.acm.org/publications/authors/submissions</a>
      </li>
      <li>
    Prepare your source files and pack them into a ZIP file that follows the directory structure shown on the TAPS page. Note: You are also required to submit PDF version of your paper. However, this PDF is just for troubleshooting – all content for the Digital Library is compiled directly from the sources.
      </li>
      <li>
    Upload the ZIP file either via the upload form on the TAPS page (only for small files) or by uploading it to the external service linked on the TAPS page.
      </li>
      <li>
    TAPS will check whether the sources are valid and can be compiled. After some time the TAPS page will either show you a success message or an error message (you need to check the page manually from time to time).
      </li>
      <li>
    In the latter case, you will be given an error log. Please fix all issues and upload the ZIP file again until the manuscript compiles successfully.
      </li>
      <li>
    Once upload and compilation are completed successfully, you need to check whether PDF and HTML versions were generated correctly. Please check whether author names, figures, equations, references, etc. are rendered correctly in both formats. If not, click the “reject” radio button in order to upload a new version.
      </li>
      <li>
    Once you are satisfied with the results, please click the “approve” radio button.
      </li>
      <li>
    The proceedings chairs will check your submission for completeness and obvious formatting problems. If there are changes required, they will contact the corresponding author by email. Further documentation on what TAPS is, can be found here: <a href="https://authors.acm.org/proceedings/production-information/taps-production-workflow">new ACM workflow</a>.
      </li>
    </ul>


    <h2>Paper Chairs</h2>
    Leah Buechley, <i>University of New Mexico</i><br>
    HyunJoo Oh, <i>Georgia Tech</i><br>
    Paul Strohmeier, <i>Saarland University</i><br>
    <br>
    For further questions please contact the Paper chairs at <a href="mailto:program_chairs@tei.acm.org">program_chairs@tei.acm.org</a>

    <h2>Associate Chairs</h2>
    <b>TBD</b>
  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}

.code {
  color: var(--green);
  font-family: Roboto Mono,sans-serif;
}
</style>
