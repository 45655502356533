<script setup>

</script>

<template>
  <main>
    <h1>Accessibility FAQ</h1>
    <b>How do I communicate accessibility needs to TEI 2025 organizers?</b>
    <p>
      When you register for the conference, there will be a registration question labeled, “Do you have a disability or require any access accommodations we should be aware of?”, where you can indicate your needs. The Equity, Accessibility, and Inclusion chairs will follow up with you to clarify your needs. You can also contact them directly at any time by emailing <a href="mailto:diversity@tei.acm.org">diversity@tei.acm.org</a>
    </p>

    <b>Who do I contact if my question is not answered here?</b>
    <p>
      If there is specific accessibility information you would like to see here, or if you wish to discuss any conference accessibility requirements, please contact our Equity, Accessibility, and Inclusion Chairs, Émeline Brulé and Nathalie Bressa, by email: <a href="mailto:diversity@tei.acm.org">diversity@tei.acm.org</a>
    </p>

    <b>When is the deadline for accessibility requests that have not been made during the registration process?</b>
    <p>
      The deadline for additional accessibility requests that were not made during the registration process is currently the 31st of December 2024. If you need support, please inform us of your accessibility requirements/requests before this deadline. You can contact the Equity, Accessibility, and Inclusion Chairs, Émeline Brulé and Nathalie Bressa, by email: <a href="mailto:diversity@tei.acm.org">diversity@tei.acm.org</a>
    </p>

    <b>What are expectations around inclusivity, diversity, and harassment at TEI 2025?</b>
    <p>
      We ask that all conference attendees respect individuals’ identities. Please use their names and pronouns. If you’re not sure – ask! Please review expectations stated in the <a href="https://dl.acm.org/doi/10.1145/3183559.3183563">ACM SIGCHI Policy on Harassment and Discrimination</a>.
    </p>

    <b>What are the arrangements for presenters with accessibility needs?</b>
    <p>
      If you would like assistance during a presentation or have other accessibility requests please contact the Equity, Accessibility, and Inclusion chairs, Émeline Brulé and Nathalie Bressa, at <a href="mailto:diversity@tei.acm.org">diversity@tei.acm.org</a> by the 31st of December 2024 or as soon as possible thereafter.
    </p>

    <b>Will there be loud music, strobe lighting, spotlights, or other strong sensory experiences?</b>
    <p>
      We are instructing authors to avoid strobing lights in their videos. We are working with other chairs to ensure a pleasant experience in all conference venues, in particular for more social events like demos night.
    </p>

    <h2>On-site</h2>
    <b>How do I get to the conference venue?</b>
    <p>
      Details of getting to and from conference venues are available on the main TEI website.
    </p>

    <b>What is the conference space like?</b>
    <p>
      We are waiting for the full list of venues but TEI 2025 will be primarily held in Talence at the Domaine du Haut Carré of University of Bordeaux. The site can be reached by tram and buses: stops are <a href="https://www.infotbm.com/en/practical-information/accessibility.html">wheelchair accessible</a> and buses have a ramp. There is a 500 to 700 meters stroll from the stops to the main buildings. Parking is also available at buildings A28, A29, A22 A30, B8, and Domaine Haut-Carré.
    </p>

    <b>Will the conference be accessible by wheelchair?</b>
    <p>
      Yes, the conference will be accessible to wheelchair users, as there are lifts or ramps in each building. Poster sessions will have space for a wheelchair to move between the posters.
    </p>
    <p>
      In case of an emergency and call for evacuation, designated student volunteers will assist and orient people with disabilities (e.g., blind and visually impaired attendees, people with hearing impairments) to safety.
    </p>

    <b>What restroom facilities are available?</b>
    <p>
      We will arrange for gender-neutral bathrooms at the main venue. Accessible bathrooms are available.
    </p>

    <b>Can I bring my guide dog?</b>
    <p>
      Yes, service animals are permitted within each of the buildings. We remind all attendees that guide dogs are working animals, and ask that they refrain from petting, distracting, or otherwise preventing them from doing their job. We ask that assistance dogs be clearly identifiable.
    </p>

    <b>Will there be an FM Assisted Listening System available?</b>
    <p>
      Please request this during the registration process.
    </p>

    <b>Will the conference be smoke-free?</b>
    <p>
      Smoking is prohibited in all internal areas of the venues. Smokers are allowed in any outdoor space.
    </p>

    <b>What food service will be provided?</b>
    <p>
      During coffee breaks, attendees can expect light refreshments. We will aim for finger foods at the welcome reception and demos night to be vegan. Lunch will not be served but there are options nearby providing vegetarian, vegan and gluten free options. They will be listed on the website ahead of time.
    </p>
    <p>
      If you have specific questions about dietary requirements, please contact the Equity, Accessibility, and Inclusion chairs, Émeline Brulé and Nathalie Bressa, by email at <a href="mailto:diversity@tei.acm.org">diversity@tei.acm.org</a> as early as possible. We also ask about dietary requirements in the registration form.
    </p>
    <b>Will there be live captioning?</b>
    <p>
      We are working on securing a budget for live captioning.
    </p>
  </main>
</template>

<style scoped>

</style>
