<template>
<div>
  <picture>
    <source :srcset="getImgSrc('-min')" media="(max-width: 768px)"/>
    <img class="logo" :src="getImgSrc('')"/>
  </picture>
  <p class="name">{{name}}</p>
  <p class="affiliation">{{affiliation}}</p>
</div>
</template>

<script setup>
const props = defineProps(['name', 'affiliation', 'portrait'])

function getImgSrc (min) {
  return require('@/assets/organizersPortraits/' + props.portrait + min + '.webp')
}
</script>

<style scoped>
picture {
  grid-row: span 2;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

div {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 2px 10px;
  margin-bottom: 10px;
}

.name {
  font-weight: bolder;
  display: flex;
  align-items: flex-end;
}

.affiliation {
  font-style: italic;
}

p {
  margin: 0;
}

@media (min-width: 768px) {
  img {
    width: 300px;
    height: 300px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
