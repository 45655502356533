<script setup>

</script>

<template>
  <main>
    <h1>Visa Information</h1>

    <p>ACM is able to provide visa support letters to TEI’25 attendees as well as accepted authors and presenters.
    </p>
    <p>Unsure if you need a visa to enter France? Please check the <a
        href="https://france-visas.gouv.fr/en/visa-wizard">information provided by the French government</a>.</p>
    <p>There are two ways to get visa letters. Please choose one of the two and do not overwhelm the system by doing
      both.
    </p>
    <p>The fastest way is to register to TEI’25 online. As a part of the registration workflow – you will be given
      instructions to request for the visa letter to be issued by the ACM.&nbsp;
    </p>
    <p>Otherwise, you can request a visa support letter via email to <b>supportletters@acm.org</b>.&nbsp;
    </p>
    <p>The information below should be included with the request:</p>
    <ul>
      <li aria-level="1">Your name as it appears on your passport.
      </li>
      <li aria-level="1">Your current postal mailing address.</li>
      <li aria-level="1">The name of the conference you are registering for (TEI’25).&nbsp;
      </li>
      <li aria-level="1">Your registration confirmation number.
      </li>
      <li aria-level="1">If you have any papers accepted for the conference, please provide the title and indicate
        whether you are the “sole author” or a “co-author.”
      </li>
      <li aria-level="1">Authors may indicate their paper title. If no paper, speakers can provide the title of their
        presentation/demo.
      </li>
    </ul>
    <p>Please allow up to 10 business days to receive a letter.&nbsp;All requests are handled in the order they are received.&nbsp;</p>
    <p>Please note that the TEI’25 organisers cannot issue visa support letters.
    </p>
    <p><br><br><br></p>
  </main>
</template>

<style scoped>

</style>
