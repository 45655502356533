const vLazyLoad = {
    mounted: (el) => {
        function loadImage () {
            const imageElement = Array.from(el.children).find(
                el => el.nodeName === "IMG"
            );
            if (imageElement) {
                imageElement.addEventListener("load", () => {
                    console.log('loaded')
                    setTimeout(() => el.classList.add("loaded"), 100);
                });
                imageElement.addEventListener("error", (e) => console.log("error", e));
                imageElement.src = imageElement.dataset.url;
            }
        }

        function handleIntersect (entries, observer) {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    loadImage()
                    observer.unobserve(el)
                }
            }
        }

        function createObserver () {
            const options = {
                root: null,
                threshold: '0'
            }
            const observer = new IntersectionObserver(handleIntersect, options)
            observer.observe(el)
        }

        if (window["IntersectionObserver"]) {
            createObserver(el)
        } else {
            loadImage(el)
        }
    }
}

export default vLazyLoad
