<template>
  <main>
    <h1>Registration</h1>
    <p>
      We tried to make TEI 2025 registration as accessible as possible while still covering the expenses of the
      conference. Registration is now open through the CVENT online registration system. Please note that for every
      accepted Paper, <strong>Pictorial</strong>, <strong>Arts</strong>, or <strong> Work-in-Progress</strong>
      submission, at least one author has to register personally.
    </p>
    <h2>Conference Registration & Fees
    </h2>
    <p>Registration to TEI 2025 will give you access to the live and interactive program. Full, in-person conference
      registration includes access to all sessions, coffee breaks, Tuesday Welcome reception, and conference dinner on
      Thursday where demos and art+performance session will take place. Online participation includes access to the live
      stream of the TEI’25 single track and provides access to a live-streamed tour of the demo and art & performance
      track session.</p>

    <div class="table_component" role="region" tabindex="0">
      <table id="t">
        <caption></caption>
        <thead>
        <tr>
          <th style="width:40%" colspan="2">&nbsp;</th>
          <th style=" text-align: center;">IN-PERSON and AUTHORS</th>
          <th style=" text-align: center;">ONLINE</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>Content</td>
          <td>
            <ul>
              <li>All sessions</li>
              <li>Coffee breaks</li>
              <li>Welcome reception</li>
              <li>Conference dinner</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Live stream of TEI'25</li>
              <li>Live tour of the demos and art track</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td rowspan="5">Early</td>
          <td>ACM/SIGCHI Members</td>
          <td>€600</td>
          <td rowspan="15">€150</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€700</td>
        </tr>
        <tr>
          <td>ACM/SIGCHI Student Members</td>
          <td>€350</td>
        </tr>
        <tr>
          <td>Student Non-Member</td>
          <td>€400</td>
        </tr>
        <tr style="border-bottom-color: #000000; border-bottom-style: solid;border-bottom-width: 5px">
          <td>Retiree</td>
          <td>€350</td>
        </tr>
        <tr>
          <td rowspan="5">Standart</td>
          <td>ACM/SIGCHI Members</td>
          <td>€700</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>ACM/SIGCHI Student Members</td>
          <td>€500</td>
        </tr>
        <tr>
          <td>Student Non-Member</td>
          <td>€550</td>
        </tr>
        <tr style="border-bottom-color: #000000; border-bottom-style: solid;border-bottom-width: 5px">
          <td>Retiree</td>
          <td>€600</td>
        </tr>
        <tr>
          <td rowspan="5">Late</td>
          <td>ACM/SIGCHI Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>ACM/SIGCHI Student Members</td>
          <td>€550</td>
        </tr>
        <tr>
          <td>Student Non-Member</td>
          <td>€600</td>
        </tr>
        <tr>
          <td>Retiree</td>
          <td>€600</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p>Potential attendee can also benefit from the <a href="https://sigchi.org/awards/gary-marsden-travel-awards/" target="_blank">Gary Marsden Travel Awards</a> and <a href="https://sigchi.org/resources/sigchi-development-fund/" target="_blank">Development Fund</a>. For more
      information, check the link: <a href="https://sigchi.org/community/membership/" target="_blank"> https://sigchi.org/community/membership/ </a>
    </p>
    <p>
      * SIGCHI membership costs $30 for professionals and $10 for students. For more info,  visit <a href="https://sigchi.org" target="_blank">sigchi.org</a>.
    </p>
  </main>
</template>

<script setup>
</script>

<style scoped>

.table_component {
  overflow: auto;
  width: 100%;
}

.table_component table {
  border: 1px solid #dededf;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  text-align: left;
}

.table_component caption {
  caption-side: top;
  text-align: left;
}

.table_component th {
  border: 1px solid #dededf;
  background-color: #eceff1;
  color: #000000;
  padding: 5px;
}

.table_component td {
  border: 1px solid #dededf;
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
}
</style>
