<template xmlns="http://www.w3.org/1999/html">
  <main>
    <h1>Special Recognition for Sustainability</h1>
    <p>
      The TEI’25 sustainability committee is excited to announce the debut of a Special Recognition for papers and
      pictorials that take exceptional measures toward sustainable research practices. With this year’s theme being
      <b><i>sustainable TEI</i></b>, this initiative aims to draw attention to sustainable research and celebrate authors’ dedication
      to sustainability. This honor is open to any project that has taken steps to be more sustainable. This includes
      works that directly address topics of sustainability; as well as works in which the authors make significant
      efforts to be more sustainable behind the scenes. There are many creative ways that HCI researchers could consider
      to make their work more sustainable and potentially earn the Special Recognition for Sustainable Practices,
      including:
    </p>
    <ul>
      <li>Public outreach or education on sustainable topics or practices</li>
      <li>Utilizing biodegradable or recycled materials</li>
      <li>Minimizing project-related travel (e.g., holding hybrid and virtual meetings)</li>
      <li>Incorporating community leaders in project funding</li>
      <li>Advocating for sustainable policy</li>
      <li>Reducing project waste/e-waste (e.g., through sustainable sourcing, reusing parts)</li>
      <li>Offsetting carbon costs (e.g., of training machine learning models)</li>
      <li>Implementing theory related to sustainability (e.g., more-than-human design, unmaking)</li>
    </ul>
    <p>
    Any of these actions or similar could potentially earn your project a Special Recognition for Sustainable Practices.
    We hope to hear many other creative ideas as well!

    See the Special Recognition winners and runners-up for Sustainable Practices at CHI’24: <a
      href="https://chi2024.acm.org/2024/05/08/special-recognition-for-sustainable-practices-winners-runners-up/">https://chi2024.acm.org/2024/05/08/special-recognition-for-sustainable-practices-winners-runners-up/</a>
    </p>
    <h2>How to Apply </h2>
    <p>
      The submission portal in PCS includes a new field for authors to describe the steps taken to make their work more
      sustainable. In 1-3 paragraphs (300 words or less), tell us what actions you’ve taken to make your project more
      sustainable, your reasoning for taking those actions, and what impact you’ve seen. This optional submission is due
      on PCS with the camera-ready version of your paper/pictorial. Papers and pictorials that receive this Special
      Recognition will be announced during the conference.
    </p>
  </main>


</template>

<script setup>
</script>

<style scoped>

</style>
