<template>
  <div class="dropdown large-display" :class="isActive ? 'active' : ''" :key="reload">
    <button class="dropbtn" @click="displayList = !displayList">
      <slot/>
      <unicon name="angle-down"/>
      <unicon name="angle-up" v-if="false"/>
    </button>
    <div class="dropdown-list" :class="displayList ? '' : 'hide'">
      <slot name="dropdown-list"></slot>
    </div>
  </div>
  <div class="dropdown small-display">
    <div class="dropbtn">
      <slot/>
    </div>
    <div class="dropdown-list">
      <slot name="dropdown-list"></slot>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'

defineProps(['isActive'])

const displayList = ref(false)
const reload = ref(0)

const router = useRouter()
const route = useRoute()
const path = computed (() => {
  return route.path
})

watch(path, () => {
  reload.value++
})
</script>

<style scoped>
.dropbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: transparent;
  margin-right: 20px;
}

.dropbtn .unicon {
  position: relative;
  top: 3px;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 100%;
  box-sizing: border-box;
}

.dropdown:hover .dropdown-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdown-list.hide {
  display: none;
}

.large-display {
  display: none;
}

@media (min-width: 1000px) {
  .dropbtn .unicon {
    display: inline-block;
  }

  .dropdown {
    width: initial;
  }

  .dropdown-list {
    display: none;
    width: initial;
    position: absolute;
    background-color: var(--background-alt);
    min-width: 160px;
    box-shadow: var(--shadow);
    padding: 5px 15px;
    z-index: 10;
    white-space: nowrap;

    border-radius: 0 10px 10px 10px;
  }

  .dropdown.left .dropdown-list {
    right: 0;
    align-items: flex-end;
    border-radius: 10px 0 10px 10px;
  }

  .large-display {
    display: initial;
  }

  .small-display {
    display: none;
  }
}
</style>
